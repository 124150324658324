export const Projects = (props) => {
  return (
    <div id='projects' className='text-center'>
      <div className='container'>
        <div className='project-title'>
          <h2>Projects</h2>
          <p>
         
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  {/* <i className={d.icon}></i> */}
                  <img src={`img/${d.icon}.png`}  alt="" />{" "}
                  {/* <div className="col-xs-12 col-md-6">
            {" "}
           
          </div>  */}
                  <div className='project-desc'>
                 
                    <h3>{d.name}</h3>
                    {/* <p>{d.text}</p> */}
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
