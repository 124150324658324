import React from 'react';

import {
    BrowserRouter as Router ,Routes,  Route 
  } from "react-router-dom";

import App from './App';
import PrivacyPolicy from './components/pp';

 
 const AppRoutes = () => {
     return (
        
         <Router>
             <Routes>
                 <Route path="/" exact element={<App/>} />
                 <Route path="/privacy" exact element={<PrivacyPolicy/>} />
             </Routes>
         </Router>
     );
 };
 
 export default AppRoutes;
 